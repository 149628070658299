import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { iRootState, Dispatch } from '../../../store';
import { IUserMap, ICoordXY } from '../../../models/types';
import { mapColor } from '../../../theme';
import { paths } from '../../Root/paths';
import getFullName from '../../../utils/getFullName';
import { getUserStatuses } from '../utils';
import { ReactComponent as Error } from '../../../assets/icons/error.svg';

import UserMarkerListItemTooltip from './UserMarkerListItemTooltip';
import { getContrastTextColor } from '../../../utils/convertColors';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    borderRadius: 2,
    backgroundColor: (user: IUserMap) => (user.trackInfo ? user.trackInfo.mapColor : user.mapColor),
    padding: `0 ${spacing(1)}px`,
    marginLeft: spacing(2),
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: (user: IUserMap) =>
      getContrastTextColor(user.trackInfo ? user.trackInfo.mapColor : user.mapColor)
  },
  spacing: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  errorIcon: {
    fontSize: 16,
    color: mapColor.normalOrange
  },
  point: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: (user: IUserMap) => (user.trackInfo ? user.trackInfo.mapColor : user.mapColor),
    width: 15,
    height: 15
  },
  pulse: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: (user: IUserMap) => (user.trackInfo ? user.trackInfo.mapColor : user.mapColor),
    opacity: 0.5,
    width: 15,
    height: 15,
    animation: '$pulseEffect 1s infinite reverse'
  },
  '@keyframes pulseEffect': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(2)'
    }
  }
}));

const mapState = (state: iRootState) => ({
  activeUser: state.infrastructureUsers.item,
  isFocus: state.infrastructureUsers.isFocus,
  isGeo: state.infrastructureMap.layers.isGeo,
  isTabularNumberUsed: state.admin.isTabularNumberUsed,
  isPulseUsed: state.admin.isPulseUsed,
  isProtectionsUsed: state.admin.isProtectionsUsed,
  isTitleUsed: state.admin.isTitleUsed
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    focusOn: d.infrastructureUsers.focusOn,
    focusUser: d.infrastructureUsers.focusUser
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = {
  user: IUserMap;
} & connectedProps;

const UserMarkerListItem: FC<IProps> = ({
  focusUser,
  focusOn,
  user,
  activeUser,
  isFocus,
  isGeo,
  isTabularNumberUsed,
  isPulseUsed,
  isProtectionsUsed,
  isTitleUsed
}) => {
  const {
    username,
    trackInfo,
    position: { lat, lon, x, y }
  } = user;

  const classes = useStyles(user);
  const history = useHistory();

  const position: ICoordXY = isGeo ? [lat, lon] : [y, x];
  const isStates = Boolean(
    trackInfo && trackInfo.states && getUserStatuses(trackInfo.states).length
  );
  const fullName = getFullName(user);

  useEffect(() => {
    if (isFocus && activeUser && activeUser.username === username) {
      focusUser(position);
    }
  }, [user, isFocus, activeUser]);

  const renderIcon = (
    <>
      <Grid className={classes.title}>
        <Grid container alignItems="center" wrap="nowrap" className={classes.spacing}>
          <span>{fullName}</span>
          {isStates && <Error className={classes.errorIcon} />}
        </Grid>
      </Grid>
      <Grid className={classes.pulse} />
      <Grid className={classes.point} />
    </>
  );

  const icon = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: [7, 27],
    html: renderToString(renderIcon)
  });

  const handleClick = () => {
    history.push(paths.map.office.users.show(username));
    focusOn();
  };

  return (
    <>
      {position[0] && position[1] && (
        <Marker
          key={position[0] + position[1]}
          position={position}
          icon={icon}
          onClick={handleClick}
        >
          <UserMarkerListItemTooltip
            user={user}
            isTabularNumberUsed={isTabularNumberUsed}
            isPulseUsed={isPulseUsed}
            isProtectionsUsed={isProtectionsUsed}
            isTitleUsed={isTitleUsed}
          />
        </Marker>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(UserMarkerListItem);
